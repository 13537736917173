import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Utils
import DateTime from "luxon/src/datetime.js";
import { NavLink } from "react-router-dom";

// Redux actions
import { updateLocalOrder } from "../../../../actions/localOrder";
import { updateEditOrder } from "../../../../actions/editOrder";
import moment from "moment";

// Form
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import { resetEditOrderState } from "../../../../actions/editOrder";
import { clearOrderError } from "../../../../actions/orders";

function ScheduleStep(props) {
  let history = useHistory();

  const [edit, setEdit] = useState(props.edit);
  const [order, setOrder] = useState();
  const [comforterMinDeliveryDays, setComforterMinDeliveryDays] = useState(1);
  const [dryCleanMinDeliveryDays, setDryCleanMinDeliveryDays] = useState(3);
  const [tailoringMinDeliveryDays, setTailoringMinDeliveryDays] = useState(3);
  const [washPressMinDeliveryDays, setWashPressMinDeliveryDays] = useState(3);
  let [showMultipleDeliveries, setShowMultipleDeliveries] = useState(false);
  let [earlierDeliveryService, setEarlierDeliveryService] = useState(null);

  const [dryCleanOrder, setDryCleanOrder] = useState();
  const [washFoldOrder, setWashFoldOrder] = useState();
  const [organicWashFoldOrder, setOrganicWashFoldOrder] = useState();
  const [comforterOrder, setComforterOrder] = useState();
  const [washPressOrder, setWashPressOrder] = useState();
  const [tailoringOrder, setTailoringOrder] = useState();
  const [pickUpTimes, setPickUpTimes] = useState();
  const [deliveryTimes, setDeliveryTimes] = useState();

  const [firstPickUp, setFirstPickUp] = useState({
    value: 11,
    label: "11-12pm",
  });
  const [lastPickUp, setLastPickUp] = useState({ value: 21, label: "9-10pm" });
  const [firstDelivery, setFirstDelivery] = useState({
    value: 11,
    label: "11-12pm",
  });
  const [lastDelivery, setLastDelivery] = useState({
    value: 21,
    label: "9-10pm",
  });

  const [firstSameDayPickUp, setFirstSameDayPickUp] = useState({
    value: 11,
    label: "11-12pm",
  });

  const [lastSameDayPickUp, setLastSameDayPickUp] = useState({
    value: 11,
    label: "11-12pm",
  });

  const [firstSameDayDelivery, setFirstSameDayDelivery] = useState({
    value: 18,
    label: "7-8pm",
  });

  // const [lastSameDayDelivery, setLastSameDayDelivery] = useState({ value: 19, label: "9-10pm" });

  // Pre-populate with local order state values
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (
      props.editOrder &&
      props.editOrder.orderId &&
      props.auth.authenticated
    ) {
      setEdit(true);
      setOrder(props.editOrder);
    } else {
      setOrder(props.localOrder);
    }
  }, [props.editOrder, props.localOrder]);

  // Watch form values
  let selectedPickUpDate = watch("pickUpDate");
  let selectedPickUpTime = watch("pickUpTime");
  let selectedDeliveryDate = watch("deliveryDate");
  let selectedDeliveryTime = watch("deliveryTime");

  let selectedDeliveryDate2 = watch("deliveryDate2");
  let selectedDeliveryTime2 = watch("deliveryTime2");
  let multipleDeliveries = watch("multipleDeliveries");

  useEffect(() => {
    if (order) {
      setWashFoldOrder(order.washFoldOrder);
      setOrganicWashFoldOrder(order.organicWashFoldOrder);
      setComforterOrder(order.comforterOrder);
      setTailoringOrder(order.tailoringOrder);
      setDryCleanOrder(order.dryCleanOrder);
      setWashPressOrder(order.washPressOrder);

      if (props.editOrder && props.editOrder.orderId && !selectedPickUpDate) {
        // Order has been edited already (has a schedule object)
        if (order.schedule) {
          reset({
            deliveryDate: order.schedule.deliveryDate
              ? new Date(order.schedule.deliveryDate)
              : null,
            pickUpDate: order.schedule.pickUpDate
              ? new Date(order.schedule.pickUpDate)
              : null,
            deliveryTime: order.schedule.deliveryTime
              ? order.schedule.deliveryTime
              : null,
            pickUpTime: order.schedule.pickUpTime
              ? order.schedule.pickUpTime
              : null,
            deliveryDate2:
              order.schedule.deliveryDate2 && order.schedule.deliveryDate2
                ? new Date(order.schedule.deliveryDate2)
                : null,
            deliveryTime2: order.schedule.deliveryTime2
              ? order.schedule.deliveryTime2
              : null,
            multipleDeliveries: order.schedule.multipleDeliveries
              ? order.schedule.multipleDeliveries
              : null,
          });

          // Order to edit populated from database
        } else {
          reset({
            deliveryDate: order.deliveryDate
              ? new Date(order.deliveryDate)
              : null,
            pickUpDate: order.pickUpDate ? new Date(order.pickUpDate) : null,
            deliveryTime: order.deliveryTime ? order.deliveryTime : null,
            pickUpTime: order.pickUpTime ? order.pickUpTime : null,
            deliveryDate2:
              order.deliveryDate2 && order.deliveryDate2
                ? new Date(order.deliveryDate2)
                : null,
            deliveryTime2: order.deliveryTime2 ? order.deliveryTime2 : null,
            multipleDeliveries: order.multipleDeliveries
              ? order.multipleDeliveries
              : null,
          });
        }
      } else if (!selectedPickUpDate) {
        reset({
          deliveryDate: order.schedule
            ? new Date(order.schedule.deliveryDate)
            : null,
          pickUpDate: order.schedule
            ? new Date(order.schedule.pickUpDate)
            : null,
          deliveryTime: order.schedule ? order.schedule.deliveryTime : null,
          pickUpTime: order.schedule ? order.schedule.pickUpTime : null,
          deliveryDate2:
            order.schedule && order.schedule.deliveryDate2
              ? new Date(order.schedule.deliveryDate2)
              : null,
          deliveryTime2: order.schedule ? order.schedule.deliveryTime2 : null,
          multipleDeliveries: order.schedule
            ? order.schedule.multipleDeliveries
            : null,
        });
      }
    }
  }, [order]);

  // Allow for multiple deliveries based on services selected
  useEffect(() => {
    if (
      order &&
      (washFoldOrder || organicWashFoldOrder || comforterOrder) &&
      (washPressOrder || dryCleanOrder || tailoringOrder)
    ) {
      setShowMultipleDeliveries(true);
      if (washFoldOrder) {
        setEarlierDeliveryService({
          label: "Wash & Fold",
          total: washFoldOrder.quote,
        });
      } else if (organicWashFoldOrder) {
        setEarlierDeliveryService({
          label: "Organic Wash & Fold",
          total: organicWashFoldOrder.quote,
        });
      } else if (comforterOrder) {
        setEarlierDeliveryService({
          label: "Comforter",
          total: comforterOrder.quote,
        });
      }
    }
  }, [order]);

  // Create default time Select options, 11-12pm to 9-10pm
  const defaultPickUpTimes = [
    { value: 7, label: "7-8am" },
    { value: 8, label: "8-9am" },
    { value: 9, label: "9-10am" },
    { value: 10, label: "10-11am" },
    { value: 11, label: "11am-12pm" },
    { value: 12, label: "12-1pm" },
  ];

  for (let i = 13; i < 22; i++) {
    defaultPickUpTimes.push({ value: i, label: `${i - 12}-${i - 11}pm` });
  }

  const defaultDeliveryTimes = [
    { value: 7, label: "7-8am" },
    { value: 8, label: "8-9am" },
    { value: 9, label: "9-10am" },
    { value: 10, label: "10-11am" },
    { value: 11, label: "11am-12pm" },
    { value: 12, label: "12-1pm" },
  ];

  for (let i = 13; i < 22; i++) {
    defaultDeliveryTimes.push({ value: i, label: `${i - 12}-${i - 11}pm` });
  }

  useEffect(() => {
    if (props.settings.settings) {
      let set = props.settings.settings;

      setComforterMinDeliveryDays(set.comforterMinDeliveryDates);
      setDryCleanMinDeliveryDays(set.dryCleanMinDeliveryDays);
      setTailoringMinDeliveryDays(set.tailoringMinDeliveryDays);
      setWashPressMinDeliveryDays(set.washPressMinDeliveryDays);

      setDeliveryTimes(defaultDeliveryTimes);

      let updatedPickUpTimes, updatedDeliveryTimes;

      if (set.firstPickUp) {
        updatedPickUpTimes = defaultPickUpTimes.filter((time) => {
          return time.value >= set.firstPickUp.value;
        });
        setPickUpTimes(updatedPickUpTimes);
        setFirstPickUp(set.firstPickUp);
      } else {
        setPickUpTimes(defaultPickUpTimes);
      }

      if (set.lastPickUp) {
        updatedPickUpTimes = updatedPickUpTimes.filter((time) => {
          return time.value <= set.lastPickUp.value;
        });
        setPickUpTimes(updatedPickUpTimes);
        setLastPickUp(set.lastPickUp);
      }

      if (set.firstDelivery) {
        updatedDeliveryTimes = defaultDeliveryTimes.filter((time) => {
          return time.value >= set.firstDelivery.value;
        });
        setDeliveryTimes(updatedDeliveryTimes);
        setFirstDelivery(set.firstDelivery);
      } else {
        setDeliveryTimes(defaultDeliveryTimes);
      }

      if (set.lastDelivery) {
        updatedDeliveryTimes = updatedDeliveryTimes.filter((time) => {
          return time.value <= set.lastDelivery.value;
        });
        setDeliveryTimes(updatedDeliveryTimes);
        setLastDelivery(set.lastDelivery);
      }

      if (set.firstSameDayPickUp) {
        setFirstSameDayPickUp(set.firstSameDayPickUp);
      }

      if (set.lastSameDayPickUp) {
        setLastSameDayPickUp(set.lastSameDayPickUp);
      }

      if (set.firstSameDayDelivery) {
        setFirstSameDayDelivery(set.firstSameDayDelivery);
      }

      // if (set.lastSameDayDelivery) {
      //     setLastSameDayDelivery(set.lastSameDayDelivery);
      // }
    }
  }, [props.settings]);

  // Today
  let currentHourDateTime = DateTime.local().setZone("America/New_York");
  let dayOfWeek = currentHourDateTime.weekday;
  let currentHour = currentHourDateTime.c.hour;

  let nextHour = currentHourDateTime.c.hour + 1;

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  let oneMonthAgo = moment().subtract(1, "months");
  oneMonthAgo = new Date(oneMonthAgo);

  let defaultPickUpMinDate =
    !props.auth.user || (props.auth.user && props.auth.user.role === "Customer")
      ? today
      : oneMonthAgo;

  let defaultDeliveryMinDate =
    props.auth.user && props.auth.user.role === "Customer"
      ? today
      : oneMonthAgo;

  const [pickUpMinDate, setPickUpMinDate] = useState(defaultPickUpMinDate);
  const [deliveryMinDate, setDeliveryMinDate] = useState(
    defaultDeliveryMinDate
  );
  const [deliveryMinDate2, setDeliveryMinDate2] = useState(
    defaultDeliveryMinDate
  );
  const [availablePickUpTimes, setAvailablePickUpTimes] = useState();

  const [availableDeliveryTimes, setAvailableDeliveryTimes] = useState();
  const [availableDeliveryTimes2, setAvailableDeliveryTimes2] = useState();

  // If selected pick up date changes
  useEffect(() => {
    if (selectedPickUpDate) {
      // PICK UP MIN DATE AND TIME LOGIC ---------------------------------- >>
      // If the next hour block is 9-10pm or later

      if (nextHour > lastPickUp.value || nextHour < 2) {
        // If it's too late to pick up today, show all available times
        // and set min pick up date to tomorrow
        let newPickUpMinDate = pickUpMinDate;
        newPickUpMinDate.setDate(new Date().getDate() + 1);

        setPickUpMinDate(newPickUpMinDate);
        setAvailablePickUpTimes(pickUpTimes);
      } else {
        // Else if pick up today is still available (before 9-10pm)
        let pickupDateObj = new Date(selectedPickUpDate);
        pickupDateObj.setHours(0, 0, 0, 0);

        // If pick up is SELECTED for today, only show remaining time slots for the day (else default to all available times)
        if (pickupDateObj.getTime() == today.getTime()) {
          setAvailablePickUpTimes(
            pickUpTimes.filter((times) => {
              return times.value >= nextHour;
            })
          );
        } else {
          setAvailablePickUpTimes(pickUpTimes);
        }
      }

      // If pick up date is selected for today and a pick up time is already selected, make sure the time hasn't passed yet - if it has force a reset
      if (selectedPickUpDate && selectedPickUpTime) {
        if (new Date(selectedPickUpDate).getDay() === new Date().getDay()) {
          if (nextHour > selectedPickUpTime.value || nextHour < 2) {
            // Don't do this for admins because admins may select a day in the past
            if (
              (props.auth.user && props.auth.user.role == "User") ||
              (props.auth.user && props.auth.user.role == "user")
            ) {
              setValue("pickUpTime", null);
            }
          }
        }
      }

      // DELIVERY MIN DATE AND TIME LOGIC ---------------------------------- >>

      if (washFoldOrder) {
        if (selectedPickUpDate) {
          let pickupDateObj = new Date(selectedPickUpDate);
          pickupDateObj.setHours(0, 0, 0, 0);

          // If pick up is selected for TODAY and same day delivery enabled
          if (
            props.settings.settings.washFoldMinDeliveryDays === 0 &&
            pickupDateObj.getDate() == today.getDate()
          ) {
            // If a pick up time has been selected
            if (selectedPickUpTime) {
              // If pick up is before noon, delivery is for after 7pm
              if (selectedPickUpTime.value <= firstPickUp.value) {
                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );
              } else {
                let theNextDay = new Date(selectedPickUpDate);

                theNextDay.setDate(theNextDay.getDate() + 1);
                theNextDay.setHours(0, 0, 0, 0);

                // Else next available delivery is for tomorrow, 24 hours after pick up
                // deliveryMinDate = new Date(tomorrow);
                setDeliveryMinDate(new Date(tomorrow));
                setDeliveryMinDate2(new Date(tomorrow));

                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                // Update values to ensure 24 hour minimum turnaround time for non-same day delivery
                if (
                  selectedDeliveryTime &&
                  availableDeliveryTimes &&
                  availableDeliveryTimes.length
                ) {
                  // If the delivery is for the next day, and the new selected pickup time does not allow for 24 hours, change the delivery time
                  if (
                    selectedDeliveryTime.value <
                      availableDeliveryTimes[0].value &&
                    selectedDeliveryDate.getDay() == theNextDay.getDay()
                  ) {
                    setValue("deliveryTime", availableDeliveryTimes[0]);
                  }
                }
              }
            }
          } else {
            // If pick up is for NOT TODAY
            // If a pick up time has been selected
            if (selectedPickUpTime) {
              // If pick up is before noon, delivery is for after 7pm
              if (
                props.settings.settings.washFoldMinDeliveryDays === 0 &&
                selectedPickUpTime.value <= firstSameDayPickUp.value
              ) {
                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setDeliveryMinDate(selectedPickUpDate);
                setDeliveryMinDate2(selectedPickUpDate);
              } else {
                let nextDay = new Date(selectedPickUpDate);

                nextDay.setDate(nextDay.getDate() + 1);
                nextDay.setHours(0, 0, 0, 0);

                // Else next available delivery is for 24 hours after pick up
                setDeliveryMinDate(nextDay);
                setDeliveryMinDate2(nextDay);

                // Forces a delivery date update if the minimum date changes and current delivery date is too early
                if (
                  new Date(deliveryMinDate) > new Date(selectedDeliveryDate)
                ) {
                  selectedDeliveryDate = deliveryMinDate;
                }

                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                // Update values to ensure 24 hour minimum turnaround time for non-same day delivery
                if (
                  selectedDeliveryTime &&
                  availableDeliveryTimes &&
                  availableDeliveryTimes.length
                ) {
                  // If the delivery is for the next day, and the new selected pickup time does not allow for 24 hours, change the deliveryt ime
                  if (
                    selectedDeliveryTime.value <
                      availableDeliveryTimes[0].value &&
                    selectedDeliveryDate.getDay() == nextDay.getDay()
                  ) {
                    setValue("deliveryTime", availableDeliveryTimes[0]);
                  }
                }
              }
            } else {
              // If no pick up time has been selected
              let nextDay = new Date(selectedPickUpDate);
              nextDay.setDate(nextDay.getDate() + 1);
              nextDay.setHours(0, 0, 0, 0);

              setDeliveryMinDate(nextDay);
              setDeliveryMinDate2(nextDay);
            }
          }
        }

        if (selectedDeliveryDate && selectedPickUpTime) {
          if (selectedDeliveryDate.getDay() !== selectedPickUpDate.getDay()) {
            // If pick up is for before noon and delivery is NOT same day, allow all delivery times
            //  If pick up is after noon and delivery is NOT the day after pick up, allow all delivery times
            if (
              selectedPickUpTime.value <= firstSameDayPickUp.value &&
              selectedDeliveryDate.getDay() !== selectedPickUpDate.getDay()
            ) {
              setAvailableDeliveryTimes(deliveryTimes);
            } else {
              let dayAfterPickUp = new Date(selectedPickUpDate);
              dayAfterPickUp.setDate(dayAfterPickUp.getDate() + 1);

              if (selectedDeliveryDate.getDay() !== dayAfterPickUp.getDay()) {
                setAvailableDeliveryTimes(deliveryTimes);
              }
            }
          }
        }

        if (selectedDeliveryDate2 && selectedPickUpTime) {
          if (selectedDeliveryDate2.getDay() !== selectedPickUpDate.getDay()) {
            // If pick up is for before noon and delivery2 is NOT same day, allow all delivery times
            if (selectedPickUpTime.value <= firstSameDayPickUp.value) {
              setAvailableDeliveryTimes2(deliveryTimes);
            } else {
              // If pick up is after noon and delivery2 is NOT the day after pick up, allow all delivery times
              let dayAfterPickUp = new Date(selectedPickUpDate);
              dayAfterPickUp.setDate(dayAfterPickUp.getDate() + 1);

              if (selectedDeliveryDate2.getDay() !== dayAfterPickUp.getDay()) {
                setAvailableDeliveryTimes2(deliveryTimes);
              }
            }
          }
        }
      }

      if (organicWashFoldOrder) {
        if (selectedPickUpDate) {
          let pickupDateObj = new Date(selectedPickUpDate);
          pickupDateObj.setHours(0, 0, 0, 0);

          // If pick up is selected for TODAY and same day delivery enabled
          if (
            props.settings.settings.organicWashFoldMinDeliveryDays === 0 &&
            pickupDateObj.getDate() == today.getDate()
          ) {
            // If a pick up time has been selected
            if (selectedPickUpTime) {
              // If pick up is before noon, delivery is for after 7pm
              if (selectedPickUpTime.value <= firstPickUp.value) {
                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );
              } else {
                let theNextDay = new Date(selectedPickUpDate);

                theNextDay.setDate(theNextDay.getDate() + 1);
                theNextDay.setHours(0, 0, 0, 0);

                // Else next available delivery is for tomorrow, 24 hours after pick up
                // deliveryMinDate = new Date(tomorrow);
                setDeliveryMinDate(new Date(tomorrow));
                setDeliveryMinDate2(new Date(tomorrow));

                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                // Update values to ensure 24 hour minimum turnaround time for non-same day delivery
                if (
                  selectedDeliveryTime &&
                  availableDeliveryTimes &&
                  availableDeliveryTimes.length
                ) {
                  // If the delivery is for the next day, and the new selected pickup time does not allow for 24 hours, change the delivery time
                  if (
                    selectedDeliveryTime.value <
                      availableDeliveryTimes[0].value &&
                    selectedDeliveryDate.getDay() == theNextDay.getDay()
                  ) {
                    setValue("deliveryTime", availableDeliveryTimes[0]);
                  }
                }
              }
            }
          } else {
            // If pick up is for NOT TODAY
            // If a pick up time has been selected
            if (selectedPickUpTime) {
              // If pick up is before noon, delivery is for after 7pm
              if (
                props.settings.settings.organicWashFoldMinDeliveryDays === 0 &&
                selectedPickUpTime.value <= firstSameDayPickUp.value
              ) {
                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setDeliveryMinDate(selectedPickUpDate);
                setDeliveryMinDate2(selectedPickUpDate);
              } else {
                let nextDay = new Date(selectedPickUpDate);

                nextDay.setDate(nextDay.getDate() + 1);
                nextDay.setHours(0, 0, 0, 0);

                // Else next available delivery is for 24 hours after pick up
                setDeliveryMinDate(nextDay);
                setDeliveryMinDate2(nextDay);

                // Forces a delivery date update if the minimum date changes and current delivery date is too early
                if (
                  new Date(deliveryMinDate) > new Date(selectedDeliveryDate)
                ) {
                  selectedDeliveryDate = deliveryMinDate;
                }

                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                // Update values to ensure 24 hour minimum turnaround time for non-same day delivery
                if (
                  selectedDeliveryTime &&
                  availableDeliveryTimes &&
                  availableDeliveryTimes.length
                ) {
                  // If the delivery is for the next day, and the new selected pickup time does not allow for 24 hours, change the deliveryt ime
                  if (
                    selectedDeliveryTime.value <
                      availableDeliveryTimes[0].value &&
                    selectedDeliveryDate.getDay() == nextDay.getDay()
                  ) {
                    setValue("deliveryTime", availableDeliveryTimes[0]);
                  }
                }
              }
            } else {
              // If no pick up time has been selected
              let nextDay = new Date(selectedPickUpDate);
              nextDay.setDate(nextDay.getDate() + 1);
              nextDay.setHours(0, 0, 0, 0);

              setDeliveryMinDate(nextDay);
              setDeliveryMinDate2(nextDay);
            }
          }
        }

        if (selectedDeliveryDate && selectedPickUpTime) {
          if (selectedDeliveryDate.getDay() !== selectedPickUpDate.getDay()) {
            // If pick up is for before noon and delivery is NOT same day, allow all delivery times
            //  If pick up is after noon and delivery is NOT the day after pick up, allow all delivery times
            if (
              selectedPickUpTime.value <= firstSameDayPickUp.value &&
              selectedDeliveryDate.getDay() !== selectedPickUpDate.getDay()
            ) {
              setAvailableDeliveryTimes(deliveryTimes);
            } else {
              let dayAfterPickUp = new Date(selectedPickUpDate);
              dayAfterPickUp.setDate(dayAfterPickUp.getDate() + 1);

              if (selectedDeliveryDate.getDay() !== dayAfterPickUp.getDay()) {
                setAvailableDeliveryTimes(deliveryTimes);
              }
            }
          }
        }

        if (selectedDeliveryDate2 && selectedPickUpTime) {
          if (selectedDeliveryDate2.getDay() !== selectedPickUpDate.getDay()) {
            // If pick up is for before noon and delivery2 is NOT same day, allow all delivery times
            if (selectedPickUpTime.value <= firstSameDayPickUp.value) {
              setAvailableDeliveryTimes2(deliveryTimes);
            } else {
              // If pick up is after noon and delivery2 is NOT the day after pick up, allow all delivery times
              let dayAfterPickUp = new Date(selectedPickUpDate);
              dayAfterPickUp.setDate(dayAfterPickUp.getDate() + 1);

              if (selectedDeliveryDate2.getDay() !== dayAfterPickUp.getDay()) {
                setAvailableDeliveryTimes2(deliveryTimes);
              }
            }
          }
        }
      }

      if (comforterOrder) {
        if (selectedPickUpDate) {
          let pickupDateObj = new Date(selectedPickUpDate);
          pickupDateObj.setHours(0, 0, 0, 0);

          // If pick up is selected for TODAY and same day delivery enabled
          if (
            props.settings.settings.comforterMinDeliveryDays === 0 &&
            pickupDateObj.getDate() == today.getDate()
          ) {
            // If a pick up time has been selected
            if (selectedPickUpTime) {
              // If pick up is before noon, delivery is for after 7pm
              if (selectedPickUpTime.value <= firstPickUp.value) {
                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );
              } else {
                let theNextDay = new Date(selectedPickUpDate);

                theNextDay.setDate(theNextDay.getDate() + 1);
                theNextDay.setHours(0, 0, 0, 0);

                // Else next available delivery is for tomorrow, 24 hours after pick up
                // deliveryMinDate = new Date(tomorrow);
                setDeliveryMinDate(new Date(tomorrow));
                setDeliveryMinDate2(new Date(tomorrow));

                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                // Update values to ensure 24 hour minimum turnaround time for non-same day delivery
                if (
                  selectedDeliveryTime &&
                  availableDeliveryTimes &&
                  availableDeliveryTimes.length
                ) {
                  // If the delivery is for the next day, and the new selected pickup time does not allow for 24 hours, change the delivery time
                  if (
                    selectedDeliveryTime.value <
                      availableDeliveryTimes[0].value &&
                    selectedDeliveryDate.getDay() == theNextDay.getDay()
                  ) {
                    setValue("deliveryTime", availableDeliveryTimes[0]);
                  }
                }
              }
            }
          } else {
            // If pick up is for NOT TODAY
            // If a pick up time has been selected
            if (selectedPickUpTime) {
              // If pick up is before noon, delivery is for after 7pm
              if (
                props.settings.settings.comforterMinDeliveryDays === 0 &&
                selectedPickUpTime.value <= firstSameDayPickUp.value
              ) {
                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= firstSameDayDelivery.value;
                  })
                );

                setDeliveryMinDate(selectedPickUpDate);
                setDeliveryMinDate2(selectedPickUpDate);
              } else {
                let nextDay = new Date(selectedPickUpDate);

                nextDay.setDate(nextDay.getDate() + 1);
                nextDay.setHours(0, 0, 0, 0);

                // Else next available delivery is for 24 hours after pick up
                setDeliveryMinDate(nextDay);
                setDeliveryMinDate2(nextDay);

                // Forces a delivery date update if the minimum date changes and current delivery date is too early
                if (
                  new Date(deliveryMinDate) > new Date(selectedDeliveryDate)
                ) {
                  selectedDeliveryDate = deliveryMinDate;
                }

                setAvailableDeliveryTimes(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                setAvailableDeliveryTimes2(
                  deliveryTimes.filter((times) => {
                    return times.value >= selectedPickUpTime.value;
                  })
                );

                // Update values to ensure 24 hour minimum turnaround time for non-same day delivery
                if (
                  selectedDeliveryTime &&
                  availableDeliveryTimes &&
                  availableDeliveryTimes.length
                ) {
                  // If the delivery is for the next day, and the new selected pickup time does not allow for 24 hours, change the deliveryt ime
                  if (
                    selectedDeliveryTime.value <
                      availableDeliveryTimes[0].value &&
                    selectedDeliveryDate.getDay() == nextDay.getDay()
                  ) {
                    setValue("deliveryTime", availableDeliveryTimes[0]);
                  }
                }
              }
            } else {
              // If no pick up time has been selected
              let nextDay = new Date(selectedPickUpDate);
              nextDay.setDate(nextDay.getDate() + 1);
              nextDay.setHours(0, 0, 0, 0);

              setDeliveryMinDate(nextDay);
              setDeliveryMinDate2(nextDay);
            }
          }
        }

        if (selectedDeliveryDate && selectedPickUpTime) {
          if (selectedDeliveryDate.getDay() !== selectedPickUpDate.getDay()) {
            // If pick up is for before noon and delivery is NOT same day, allow all delivery times
            //  If pick up is after noon and delivery is NOT the day after pick up, allow all delivery times
            if (
              selectedPickUpTime.value <= firstSameDayPickUp.value &&
              selectedDeliveryDate.getDay() !== selectedPickUpDate.getDay()
            ) {
              setAvailableDeliveryTimes(deliveryTimes);
            } else {
              let dayAfterPickUp = new Date(selectedPickUpDate);
              dayAfterPickUp.setDate(dayAfterPickUp.getDate() + 1);

              if (selectedDeliveryDate.getDay() !== dayAfterPickUp.getDay()) {
                setAvailableDeliveryTimes(deliveryTimes);
              }
            }
          }
        }

        if (selectedDeliveryDate2 && selectedPickUpTime) {
          if (selectedDeliveryDate2.getDay() !== selectedPickUpDate.getDay()) {
            // If pick up is for before noon and delivery2 is NOT same day, allow all delivery times
            if (selectedPickUpTime.value <= firstSameDayPickUp.value) {
              setAvailableDeliveryTimes2(deliveryTimes);
            } else {
              // If pick up is after noon and delivery2 is NOT the day after pick up, allow all delivery times
              let dayAfterPickUp = new Date(selectedPickUpDate);
              dayAfterPickUp.setDate(dayAfterPickUp.getDate() + 1);

              if (selectedDeliveryDate2.getDay() !== dayAfterPickUp.getDay()) {
                setAvailableDeliveryTimes2(deliveryTimes);
              }
            }
          }
        }
      }

      // Same-day service for extra fee if pick up before noon, else 3-day service, no service on weekends: dry clean
      if (dryCleanOrder) {
        let pickupDateObj = new Date(selectedPickUpDate);
        pickupDateObj.setHours(0, 0, 0, 0);

        // If pick up is selected for TODAY
        if (pickupDateObj.getDate() == today.getDate()) {
          // If a pick up time has been selected
          if (selectedPickUpTime) {
            // Else delivery min date will be one day after pickup, excluding weekends
            let pickupDateObj = new Date(selectedPickUpDate);
            pickupDateObj.setHours(0, 0, 0, 0);

            let count = 0;

            // Add 3 full days to the pickup date to get min delivery date, skipping weekends
            while (
              count <
              props.settings.settings.dryCleanMinDeliveryDays + 1
            ) {
              pickupDateObj.setDate(pickupDateObj.getDate() + 1);
              let day = pickupDateObj.getDay();
              if (day !== 0 && day !== 6) {
                count++;
              }
            }

            setDeliveryMinDate(pickupDateObj);

            // If pick up is before noon, delivery is for after 9pm
            if (selectedPickUpTime.value <= lastSameDayPickUp.value) {
              // changed from 20
              setAvailableDeliveryTimes(
                deliveryTimes.filter((times) => {
                  return times.value >= firstSameDayDelivery.value;
                })
              );

              // changed from 20
              setAvailableDeliveryTimes2(
                deliveryTimes.filter((times) => {
                  return times.value >= firstSameDayDelivery.value;
                })
              );
            } else {
              setAvailableDeliveryTimes(deliveryTimes);
            }
          }
        } else {
          // If pick up is for NOT TODAY
          // If a pick up time has been selected
          if (selectedPickUpTime) {
            // If pick up is before noon, delivery can be for after 9pm

            if (selectedPickUpTime.value <= firstSameDayPickUp.value) {
              // Changed from 20
              setAvailableDeliveryTimes(
                deliveryTimes.filter((times) => {
                  return times.value >= firstSameDayDelivery.value;
                })
              );

              let count = 0;

              let pickupDateObj = new Date(selectedPickUpDate);
              pickupDateObj.setHours(0, 0, 0, 0);

              while (count < 4) {
                pickupDateObj.setDate(pickupDateObj.getDate() + 1);
                let day = pickupDateObj.getDay();
                if (day !== 0 && day !== 6) {
                  count++;
                }
              }

              setDeliveryMinDate(pickupDateObj);
              setAvailableDeliveryTimes(deliveryTimes);
            } else {
              // Else delivery min date will be one day after pickup, excluding weekends
              let pickupDateObj = new Date(selectedPickUpDate);
              pickupDateObj.setHours(0, 0, 0, 0);

              let count = 0;

              // Add 3 full days to the pickup date to get min delivery date, skipping weekends
              while (count < dryCleanMinDeliveryDays + 1) {
                pickupDateObj.setDate(pickupDateObj.getDate() + 1);
                let day = pickupDateObj.getDay();
                if (day !== 0 && day !== 6) {
                  count++;
                }
              }
              setDeliveryMinDate(pickupDateObj);
              setAvailableDeliveryTimes(deliveryTimes);
            }
          } else {
            // If no pick up time has been selected
            let nextDay = new Date(selectedPickUpDate);
            nextDay.setDate(nextDay.getDate() + 1);
            nextDay.setHours(0, 0, 0, 0);

            setDeliveryMinDate(nextDay);
          }
        }
      }

      // 3-day service, no service on weekends: washpress
      if (washPressOrder) {
        if (selectedPickUpDate) {
          let pickupDateObj = new Date(selectedPickUpDate);
          pickupDateObj.setHours(0, 0, 0, 0);
          let count = 0;

          // Add 3 full days to the pickup date to get min delivery date
          while (count < washPressMinDeliveryDays + 1) {
            pickupDateObj.setDate(pickupDateObj.getDate() + 1);
            let day = pickupDateObj.getDay();
            if (day !== 0 && day !== 6) {
              count++;
            }
          }

          setDeliveryMinDate(pickupDateObj);
        }

        setAvailableDeliveryTimes(deliveryTimes);
      }

      // 3-day service, no service on weekends: tailoring
      if (tailoringOrder) {
        if (selectedPickUpDate) {
          let pickupDateObj = new Date(selectedPickUpDate);
          pickupDateObj.setHours(0, 0, 0, 0);
          let count = 0;

          // Add 3 full days to the pickup date to get min delivery date
          while (count < tailoringMinDeliveryDays + 1) {
            pickupDateObj.setDate(pickupDateObj.getDate() + 1);
            let day = pickupDateObj.getDay();
            if (day !== 0 && day !== 6) {
              count++;
            }
          }

          setDeliveryMinDate(pickupDateObj);
        }

        setAvailableDeliveryTimes(deliveryTimes);
      }
    }
  }, [
    order,
    selectedPickUpDate,
    selectedPickUpTime,
    selectedDeliveryDate,
    selectedDeliveryDate2,
    selectedDeliveryTime2,
  ]);

  // Forces a delivery date update if the minimum date changes and current delivery date is too early
  useEffect(() => {
    if (selectedDeliveryDate && deliveryMinDate) {
      if (new Date(deliveryMinDate) > new Date(selectedDeliveryDate)) {
        selectedDeliveryDate = deliveryMinDate; // Updates dropdown
        setValue("deliveryDate", deliveryMinDate); // Updates actual value
      }
    }
  }, [selectedDeliveryDate, deliveryMinDate]);

  useEffect(() => {
    if (selectedDeliveryDate2 && deliveryMinDate2) {
      if (new Date(deliveryMinDate2) > new Date(selectedDeliveryDate2)) {
        selectedDeliveryDate2 = deliveryMinDate2; // Updates dropdown
        setValue("deliveryDate2", deliveryMinDate2); // Updates actual value
      }
    }
  }, [selectedDeliveryDate2, deliveryMinDate2]);

  // Clear out selected delivery time if pickup / delivery changed to same day or next day
  useEffect(() => {
    if ((selectedPickUpDate, selectedDeliveryDate)) {
      if (selectedPickUpDate.getDay() === selectedDeliveryDate.getDay()) {
        setValue("deliveryTime", null);
      }

      let nextDay = new Date(selectedPickUpDate);

      nextDay.setDate(nextDay.getDate() + 1);
      nextDay.setHours(0, 0, 0, 0);

      if (nextDay.getDay() === selectedDeliveryDate.getDay()) {
        setValue("deliveryTime", null);
      }
    }
  }, [selectedPickUpDate, selectedDeliveryDate]);

  const onSubmit = (data) => {
    let pickUpDate = new Date(data.pickUpDate).getUTCDate();
    let deliveryDate = new Date(data.deliveryDate).getUTCDate();

    if (pickUpDate === deliveryDate) {
      if (dryCleanOrder) {
        let numItems = dryCleanOrder.items.length;
        data.dryCleanSurcharge = numItems * 2;
      }

      // if (washPressOrder) {
      //   let numItems = washPressOrder.items.length;
      //   data.washPressSurcharge = numItems * 2;
      // }
    }

    // add surcharge if one of the deliveries does not meet the minimum
    if (multipleDeliveries && earlierDeliveryService) {
      data.earlierDeliveryServiceQuote = parseFloat(
        earlierDeliveryService.total
      );
    }

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "schedule", () => {
          props.dispatch(clearOrderError());
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "schedule", () => {
          props.dispatch(clearOrderError());
          history.push("/app/order/checkout");
        })
      );
    }
  };

  const isSunday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  return (
    <div id="schedule-step" className="order-step">
      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="action-button-container" style={{ marginTop: 0 }}>
          <NavLink className="link-button bg-orange" to="/app/order/services">
            Add More Services
          </NavLink>
        </div>

        <h4 className="step-header">
          Select your PICK UP date and time window.
        </h4>

        <div className="pickup-fields-container">
          <div className="pickup-field datepicker-field">
            <Controller
              as={
                <DatePicker
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  autoComplete="off"
                  filterDate={isSunday}
                  selected={selectedPickUpDate}
                  name="pickUpDate"
                  minDate={pickUpMinDate}
                  onSelect={(date) => {
                    if (date < pickUpMinDate) {
                      setValue("pickUpDate", new Date(pickUpMinDate));
                      return pickUpMinDate;
                    }
                  }}
                />
              }
              control={control}
              name="pickUpDate"
              placeholderText="Select a Date"
            />
          </div>

          {availablePickUpTimes && (
            <div className="select-field">
              <Controller
                as={
                  <Select
                    options={availablePickUpTimes}
                    placeholder="Select One"
                  />
                }
                control={control}
                name="pickUpTime"
                rules={{
                  required: false,
                  validate: (value) => value !== "Select One",
                }}
              />
            </div>
          )}
        </div>

        {selectedPickUpDate && selectedPickUpTime && availableDeliveryTimes && (
          <>
            <h4 className="step-header">
              Select your DELIVERY date and time window.
            </h4>

            <div className="delivery-fields-container">
              <div className="delivery-field">
                <Controller
                  as={
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                      filterDate={isSunday}
                      selected={selectedDeliveryDate}
                      name={`deliveryDate`}
                      placeholderText="Select a Date"
                      minDate={deliveryMinDate}
                    />
                  }
                  control={control}
                  name="deliveryDate"
                />
              </div>

              <div className="delivery-field">
                <Controller
                  as={
                    <Select
                      options={availableDeliveryTimes}
                      placeholder="Select One"
                    />
                  }
                  control={control}
                  name="deliveryTime"
                  rules={{
                    required: false,
                    validate: (value) => value !== "Select One",
                  }}
                />
              </div>
            </div>
            {earlierDeliveryService && (
              <>
                <h4 className="step-header">
                  Select an optional earlier DELIVERY date and time window.
                </h4>
                <p>
                  An earlier delivery is available for your{" "}
                  {earlierDeliveryService ? earlierDeliveryService.label : ""}{" "}
                  service. Would you like to schedule a second delivery? Please
                  note that <em>at least one</em> of your deliveries must meet
                  the order total minimum of $
                  {props.settings.settings.washFoldMinDelivery}. If it does not,
                  we will apply the difference to the delivery with the highest
                  order total.
                </p>

                <div className="delivery-fields-container">
                  <div className="delivery-field">
                    <Controller
                      as={
                        <Select
                          options={[
                            {
                              label: "Yes - multiple deliveries",
                              value: true,
                            },
                            {
                              label: "No - single delivery",
                              value: false,
                            },
                          ]}
                          placeholder="Select One"
                        />
                      }
                      control={control}
                      name="multipleDeliveries"
                      rules={{
                        required: false,
                        validate: (value) => value !== "Select One",
                      }}
                    />
                  </div>

                  {multipleDeliveries && multipleDeliveries.value && (
                    <>
                      <div className="delivery-field">
                        <Controller
                          as={
                            <DatePicker
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              autoComplete="off"
                              filterDate={isSunday}
                              selected={selectedDeliveryDate2}
                              name={`deliveryDate2`}
                              placeholderText="Select a Date"
                              minDate={deliveryMinDate2}
                            />
                          }
                          control={control}
                          name="deliveryDate2"
                        />
                      </div>

                      <div className="delivery-field">
                        <Controller
                          as={
                            <Select
                              options={availableDeliveryTimes2}
                              placeholder="Select One"
                            />
                          }
                          control={control}
                          name="deliveryTime2"
                          rules={{
                            required: false,
                            validate: (value) => value !== "Select One",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {selectedPickUpDate &&
              selectedPickUpTime &&
              selectedDeliveryDate &&
              selectedDeliveryTime && (
                <div className="action-button-container">
                  <input
                    className="submit-button bg-green"
                    type="submit"
                    value="Continue"
                  />
                </div>
              )}
          </>
        )}
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
    schedule: state.localOrder.schedule,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
  };
};

export default connect(mapStateToProps)(ScheduleStep);
